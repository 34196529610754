import { flavorAndRoastCarousel } from "../sanity/quick-shop-carousel-queries";

type Experiment<T> = {
  id: string;
  [key: number]: T;
};

type Experiments = {
  sortBy: Experiment<boolean>;
  productComparison: Experiment<boolean>;
};

export const experiments: Experiments = {
  sortBy: {
    id: "26",
    1: false,
    2: true,
  },
  productComparison: {
    id: "41",
    1: false,
    2: true,
  },
};

export const getFlavorAndRoastModule = (
  lang: string,
) => `*[_type =="product" && !(_id in path("drafts.**")) && slug.current == "bella-donovan"]{
  "modules": modules[_type == "flavorAndRoastCarousel"]{
  _type,
  _key,
  ${flavorAndRoastCarousel(lang)}}
}`;
